import UserLayout from "components/userAccount/layout";
import React from "react";
import MyOldProjects from "components/userAccount/myOldProjects";
import Seo from "components/seo";

function index() {
  return (
    <UserLayout>
      <Seo title="Old Projects - Dashboard" />
      <MyOldProjects />      
    </UserLayout>
  );
}

export default index;
