import { useOldProject } from "hooks/Auth/useOldProject";
import React from "react";

function MyOldProjects() {
  const { data } = useOldProject();
  // console.log(data);
  return (
    <div className="">
      <section className="bg-white">
        <div className="containeR w-full">
          <div className="flex flex-wrap -ml-4">
            <div className="w-full pl-4">
              <div className="max-w-full overflow-x-auto">
                <table className="table-auto w-full">
                  <thead>
                    <tr className="bg-primary text-center">
                      <th className="w-1/6 min-w-[160px] text-lg font-semibold text-black py-4 lg:py-7 px-3 lg:px-4 border-l-0">
                        Project Name
                      </th>
                      <th className="w-1/6 min-w-[160px] text-lg font-semibold text-black py-4 lg:py-7 px-3 lg:px-4">
                        Number Of words
                      </th>
                      <th className="w-1/6 min-w-[160px] text-lg font-semibold text-black py-4 lg:py-7 px-3 lg:px-4">
                        Project Cost
                      </th>
                      <th className="w-1/6 min-w-[160px] text-lg font-semibold text-black py-4 lg:py-7 px-3 lg:px-4">
                        Started On
                      </th>
                      <th className="w-1/6 min-w-[160px] text-lg font-semibold text-black py-4 lg:py-7 px-3 lg:px-4">
                        Status
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.data?.map((item) => {
                      return (
                        <>
                          {/* {console.log(item)} */}
                          {item.subproject.map((subprojectitem, index) => {
                            {
                              // console.log(
                              //   subprojectitem.translated_document.DocumentPath
                              // );
                            }
                            return (
                              <tr key={index}>
                                <td className="text-center text-dark font-medium text-base py-5 px-2 bg-[#F3F6FF] border-b border-l border-[#E8E8E8]">
                                  {subprojectitem?.name
                                    ? subprojectitem?.name
                                    : "no data"}
                                </td>
                                <td className="text-center text-dark font-medium text-base py-5 px-2 bg-white border-b border-l border-[#E8E8E8]">
                                  {subprojectitem.noofwords
                                    ? subprojectitem.noofwords
                                    : "no data"}
                                </td>
                                <td className="text-center text-dark font-medium text-base py-5 px-2 bg-[#F3F6FF] border-b border-l border-[#E8E8E8]">
                                  {subprojectitem.cost
                                    ? `\$${subprojectitem.cost}`
                                    : "no data"}
                                </td>
                                <td className="text-center text-dark font-medium text-base py-5 px-2 bg-white border-b border-l border-[#E8E8E8]">
                                  {subprojectitem.estimatedstartdate
                                    ? subprojectitem.estimatedstartdate
                                    : "no data"}
                                </td>
                                <td className="text-center text-dark font-medium text-base py-5 px-2 bg-[#F3F6FF] border-b border-l border-[#E8E8E8]">
                                  {subprojectitem.statusword
                                    ? subprojectitem.statusword
                                    : "no data"}

                                  <a
                                    href={
                                      subprojectitem.translated_document
                                        .DocumentPath
                                    }
                                    className={`${
                                      subprojectitem.statusword == "Completed"
                                        ? "inline-block"
                                        : "hidden"
                                    }  ml-[20px] py-2 px-3 bg-darkMist hover:bg-drakGray rounded border-drakGray`}
                                  >
                                    Download
                                  </a>
                                </td>
                              </tr>
                            );
                          })}
                        </>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default MyOldProjects;
