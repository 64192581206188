import { useQuery } from "react-query";
import { endpoints } from "utils/endpoints";
import http from "utils/http";

const OldProject = () => {
  const newEndpoints = endpoints.auth.oldProjects;
  return http().get(newEndpoints);
};

export function useOldProject(id) {
  return useQuery(["fetchDashboard", id], () => OldProject());
}
